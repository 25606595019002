import React from "react";

import cn from "classnames";
import { Link } from "react-router-dom";

import Avatar from "../../../../components/Avatar";
import Balance from "../../../../components/Balance";
import Icon from "../../../../components/Icon";
import { capitalize } from "../../../../utils";

import LineChart from "./LineChart";
import styles from "./Row.module.sass";

const Row = ({ item }) => {
  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <div className={styles.item}>
          <div className={styles.preview}>
            <Avatar
              src={`/images/integration/${item?.title?.toLowerCase().replace("outlook", "exchangeonline")}.png`}
              alt={item.title}
            />
          </div>
          <div className={styles.details}>
            <div
              className={styles.post}
              dangerouslySetInnerHTML={{
                __html: item.title.replace("Outlook", "Exchange Online"),
              }}
            ></div>
            <div className={styles.foot}>
              <Link
                className={cn("button-stroke button-small", styles.button)}
                to={`/integration/${item.title}/details`}
              >
                See details
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Status</div>
        {item.status === "online" && (
          <div className={cn("status-green", styles.status)}>Online</div>
        )}
        {item.status === "offline" && (
          <div className={cn("status-red", styles.status)}>Offline</div>
        )}
        {item.status !== "offline" && item.status !== "online" && (
          <div className={cn("status-yellow", styles.status)}>
            {capitalize(item.status)}
          </div>
        )}
        <div className={styles.downTime}>Last down: {item.date}</div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Pulling to</div>
        <div className={styles.onlineStatus}>
          <span>
            <Icon
              name="profile-circle"
              size="20"
            />
            {Array.isArray(item.employees)
              ? `${item.employees.length} employees`
              : `${item.employees} employees`}
          </span>
        </div>
      </div>
      <div className={styles.col}>
        {item.data.length < 3 ? (
          <div className={styles.noData}>
            <Icon
              className={styles.clock}
              name="dashed-clock"
              size="24"
              viewBox="0 0 20 20"
            />
            <div>Not enough historical data.</div>
          </div>
        ) : (
          <>
            <div className={styles.label}>Activity</div>
            <LineChart
              className={styles.chart}
              item={item}
            />
            <div className={styles.chartNums}>
              <div className={styles.number}>{item.activityNumber}</div>
              <Balance
                className={styles.balance}
                value={item.difference_by_date}
                background={true}
                showValue={true}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Row;
