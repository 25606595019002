import { useEffect, useState } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { ReactComponent as AddCircled } from "assets/icons/add-circled.svg";
import { ReactComponent as SortAscIcon } from "assets/icons/sort-asc.svg";
import { ReactComponent as SortDescIcon } from "assets/icons/sort-desc.svg";
import { Modal, Icon, TextInput, TableComponent, Tag } from "components";
import { getAllEmployees, getEmployee } from "slices/employeeSlice";
import {
  getEmployeeTags,
  assignEmployeeToTag,
  unAssignEmployeeFromTag,
} from "slices/tagSlice";
import { formatDateUK } from "utils";

import styles from "./EmployeeToTagModal.module.sass";

const getColumns = (employee, onRemoveHandler, onAddHandler, onSort, sort) => [
  {
    title: (
      <div
        className={cn(styles.col, {
          [styles.selected]: sort.sort_by === "name",
        })}
      >
        <span onClick={() => onSort("name")}>
          {sort.sort_by === "name" && sort.sort_order === "desc" ? (
            <SortAscIcon />
          ) : (
            <SortDescIcon />
          )}
        </span>
        <span onClick={() => onSort("name")}>Tag</span>
      </div>
    ),
    key: "name",
    dataIndex: "name",
    render: (name) => (
      <Tag
        name={name}
        random
      />
    ),
  },
  {
    title: (
      <div
        className={cn(styles.col, {
          [styles.selected]: sort.sort_by === "count",
        })}
      >
        <span onClick={() => onSort("count")}>
          {sort.sort_by === "count" && sort.sort_order === "desc" ? (
            <SortAscIcon />
          ) : (
            <SortDescIcon />
          )}
        </span>
        <span onClick={() => onSort("count")}>Assigned to</span>
      </div>
    ),
    key: "count",
    dataIndex: "count",
    render: (count) => (
      <div className={styles.center}>
        {count > 1 ? `${count} Employees` : `${count} Employee`}
      </div>
    ),
  },
  {
    title: (
      <div
        className={cn(styles.col, {
          [styles.selected]: sort.sort_by === "last_assigned",
        })}
      >
        <span onClick={() => onSort("last_assigned")}>
          {sort.sort_by === "last_assigned" && sort.sort_order === "desc" ? (
            <SortAscIcon />
          ) : (
            <SortDescIcon />
          )}
        </span>
        <span onClick={() => onSort("last_assigned")}>Last Assigned</span>
      </div>
    ),
    key: "last_assigned",
    dataIndex: "last_assigned",
    render: (last_assigned) => (
      <div className={styles.center}>
        {last_assigned ? formatDateUK(last_assigned) : "N/A"}
      </div>
    ),
  },
  {
    render: (data) => (
      <>
        {employee?.tags_array?.includes(data.name) ? (
          <button
            className={cn("button button-small", styles.button)}
            onClick={() => onRemoveHandler(data)}
          >
            <Icon
              name="check"
              size="24"
            />
            Tag added to Employee
          </button>
        ) : (
          <button
            className={cn(
              "button button-small button-stroke-blue",
              styles.button
            )}
            onClick={() => onAddHandler(data)}
          >
            <AddCircled />
            Add Tag to Employee
          </button>
        )}
      </>
    ),
  },
];

const EmployeeToTagModal = ({ user, visible, onClose }) => {
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const { employeeTags } = useSelector((state) => state.tag);
  const { employee } = useSelector((state) => state.employee);

  const [sort, setSort] = useState({
    sort_by: "name",
    sort_order: "desc",
  });

  useEffect(() => {
    dispatch(getEmployeeTags({...sort}));
    dispatch(getEmployee(user.id));
  }, [dispatch, user.id, sort]);

  const onAddHandler = async (tag) => {
    await dispatch(assignEmployeeToTag({ tagId: tag.id, userId: user.id }));
    dispatch(getEmployee(user.id));
  };

  const onRemoveHandler = async (tag) => {
    await dispatch(unAssignEmployeeFromTag({ tagId: tag.id, userId: user.id }));
    dispatch(getEmployee(user.id));
  };

  const onCloseHandler = () => {
    onClose();
    const action = dispatch(getAllEmployees());
    toast.promise(
      action,
      {
        pending: "Updating Employees...",
        success: "Employees updated!",
        error: "Failed to update Employees!",
      },
      {
        autoClose: 1500,
      }
    );
  };

  const onSort = (sortBy) => {
    setSort((prevSort) => {
      if (prevSort.sort_by === sortBy) {
        return {
          sort_by: sortBy,
          sort_order: prevSort.sort_order === "asc" ? "desc" : "asc",
        };
      }
      return {
        sort_by: sortBy,
        sort_order: "desc",
      };
    });
  };

  return (
    <Modal
      visible={visible}
      onClose={onCloseHandler}
      outerClassName={styles.modal}
    >
      <div className={cn("title-purple", styles.title)}>
        Add Tag to Employee
        <TextInput
          className={styles.search}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search tags"
          type="text"
          name="search"
          icon="search"
        />
      </div>

      <div className={styles.head}>
        Select which Tag you’d like to add this Employee to:
      </div>

      <TableComponent
        columns={getColumns(
          employee,
          onRemoveHandler,
          onAddHandler,
          onSort,
          sort
        )}
        data={employeeTags.filter(
          (x) =>
            x.name.toLowerCase().includes(search.toLowerCase()) &&
            x.status === 0
        )}
        className={styles.table}
      />
    </Modal>
  );
};

export default EmployeeToTagModal;
